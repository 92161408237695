export const environment = {
  production: false,
  name: 'DEV',
  isLocal: false,
  auth2FA: false,
  canvasUrl: 'https://canvas.galeriekodl.cz',
  baseAdminUrl: 'https://admin.iap.dev.bckpp.com',
  baseApiUrl: 'https://api.iap.dev.bckpp.com',
  baseWsUrl: 'wss://api.iap.dev.bckpp.com',
  baseFrontUrl: 'https://iap.dev.bckpp.com',
  baseCdnUrl: 'https://iap.cdn.dev.bckpp.com'
};
